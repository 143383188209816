import { systemProperties } from '../state-management/urls/BaseURL';

class SystemProperty {

    private static hostName = window.location.hostname;

    // Getting API URL from environment variables
    static getProperty(propertyName:string ) {
        return systemProperties[propertyName];
    }

    static getEnvProperty(propertyName:string, systemName?:string) {
        if(propertyName && systemName) {
            return systemProperties[systemName+'_'+SystemProperty.getEnvironment()] + systemProperties[propertyName];
        } else {
            return systemProperties[propertyName+'_'+SystemProperty.getEnvironment()];
        }
    }

    static getEnvironment() {
        if(SystemProperty.isProd()) {
            return 'prod';
        } else if (SystemProperty.isStaging()) {
            return 'staging';
        } else if(SystemProperty.isQA()) {
            return 'qa'; 
        } else if(SystemProperty.isDev()) {
            return 'dev';
        } else if(SystemProperty.isLocal()) {
            return 'local';
        } else if(SystemProperty.isPsr()) {
            return 'psr';
        } else {
            return '';
        }
    }

    private static isDev() {
        return SystemProperty.hostName.includes('sc-heli-dashboard-bcdmblky-kxtwxzoc-omnwfyls.apps.dev.aro.forgeapp.honeywell.com') 
            || process.env.NODE_ENV.toLowerCase() === 'test'
            || SystemProperty.hostName.includes('skyconnect-dev.honeywellforge.com');
    }

    public static isProd() {
        return SystemProperty.hostName.includes('skyconnect.honeywellforge.com');
    }

    public static isQA() {
        return SystemProperty.hostName.includes('skyconnect-qa.honeywellforge.com');
    }

    public static isPsr() {
        return SystemProperty.hostName.includes('skyconnect-psr.honeywellforge.com');
    }

    private static isLocal() {
        return SystemProperty.hostName.includes('-local') 
            || SystemProperty.hostName.includes('localhost');
    }

    private static isStaging() {
        return SystemProperty.hostName.includes('skyconnect-stg.honeywellforge.com');
    }
    
}

export default SystemProperty;
