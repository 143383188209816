import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import "./global-styles.scss"
import store from './state-management/store';

// Here we are importing our stores file and spreading it across this Provider. All stores added to this will be accessible via child injects
const wrappedApp = (
  <Provider store={store}>
      <App />
  </Provider>
);

// Here the router is bootstrapped
ReactDOM.render(wrappedApp, document.getElementById('root') as HTMLElement);