import DataStorage from "./data-storage";

export const accessToken = async (code: any) => {
    try {
        let sessionInfo = JSON.parse(atob(code));
        DataStorage.setItem('customer_sub', sessionInfo.userId);
        DataStorage.setItem('email', sessionInfo.emailId);
        DataStorage.setItem('name', sessionInfo.firstName);
        DataStorage.setItem('family_name', sessionInfo.lastName);
        DataStorage.setItem('sessionId', sessionInfo.sessionId);
        DataStorage.setItem('userId', sessionInfo.userId);
        DataStorage.setItem('refreshJob', Date.now() + 55 * 60 * 1000);
        return;
    } catch (error) {
        const event = new CustomEvent('invalidToken');
        window.dispatchEvent(event);
        return;
    }
};