import React from "react";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner'
import Authorize from "./components/Authorize/Authorize";
import LandingPage from "./components/LandingPage/LandingPage";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';


const authorize = (isFirstTimeLogin: boolean) => {
  return <React.Fragment>
    <Authorize isFirstTimeLogin={isFirstTimeLogin} />
  </React.Fragment>;
};

const router = createBrowserRouter([
  {
    path: "/",
    Component: () => { return authorize(true) }
  },
  {
    path: "/authorize",
    element: <Authorize />
  },
  {
    path: "/landingPage",
    Component: LandingPage
  },
  {
    path: "*",
    Component: PageNotFound
  }
]);

const App: React.FC<any> = (props) => {
  return (
    <React.Fragment>
      <React.Suspense fallback={<ProgressSpinner />}>
        <RouterProvider router={router} />
      </React.Suspense>
    </React.Fragment>
  );
};

export default App;

