class DataStorage {

    static getItem = (key: string) => {
        return localStorage.getItem(key);
    }

    static setItem = (key: string, value: any) => {
        key && value && localStorage.setItem(key, value);
    }

    static removeKey = (key: string) => {
        localStorage.removeItem(key);
    }

    static clear = () => {
        localStorage.clear();
    }
}

export default DataStorage;