import React, { useEffect } from 'react';
import * as queryString from 'querystring';
import { useNavigate } from "react-router-dom";
import { fetchAccessToken } from '../../state-management/auth/auth-action-creator';
import { authorizeUrl } from '../../state-management/urls/BaseURL';
import DataStorage from '../../utils/data-storage';

interface AuthorizeProps {
    isFirstTimeLogin?: boolean;
}

function Authorize(props: AuthorizeProps) {

    const navigate = useNavigate();
    useEffect(() => {
        if (DataStorage.getItem("email") && DataStorage.getItem("refreshJob") && Date.now() - Number(DataStorage.getItem("refreshJob")) < 0) {
            navigate("/landingPage");
        }
        else if (props.isFirstTimeLogin) {
            const DataUrl = authorizeUrl();
            window.location.assign(DataUrl);
        } else {
            const parsedString = queryString.parse(window.location.search);
            if (parsedString['?code']) {
                const code = parsedString['?code'].toString();
                fetchAccessToken(code);
                navigate("/landingPage");
            }
            else {
                navigate("/");
            }
        }
    }, []);

    return (
        <div className="container">
            <div className="centered">
                <img src="/assets/images/logoHoneywellForge.png" alt="HoneywellForge Logo" />
                <div className="normal-text">
                    Authorizing.....
                </div>
            </div>
        </div>

    );
}

export default Authorize;