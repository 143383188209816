import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="centered">
        <img src="/assets/images/logoHoneywellForge.png" alt="HoneywellForge Logo" /> <br /><br />
        <div className="normal-text">
          Page Not Found!
        </div>
        <br />
        <Button data-testid="page-not-found-go-to-main-page-button" onClick={() => { navigate("/landingPage"); }}>Go to Main Page</Button>
      </div>
    </div>
  );

};

export default PageNotFound;