import SystemProperty from "../../utils/system-property";

export const authorizeUrl = () => SystemProperty.getEnvProperty('SSO_CLOUD_BaseURL') + 'PartnerSpId=' + SystemProperty.getEnvProperty('Ba_Sp');

export const ESPUrl = () => SystemProperty.getEnvProperty('ESP_BaseURL');

export const getGoogleAnalyticsId = () => SystemProperty.getEnvProperty('google_analytics_id');

export const systemProperties: { [x: string]: string } = {

    // Dashboard Partner Sp ID
    'Ba_Sp_prod': 'skyconnect.honeywellforge.com',
    'Ba_Sp_staging': 'skyconnect-stg.honeywellforge.com',
    'Ba_Sp_qa': 'skyconnect-qa.honeywellforge.com',
    'Ba_Sp_psr': 'skyconnect-psr.honeywellforge.com',
    'Ba_Sp_dev': 'skyconnect-dev.honeywellforge.com',
    'Ba_Sp_local': 'skyconnect-local.honeywellforge.com',
 
    // CWA CLOUD Base URLs 
    'SSO_CLOUD_BaseURL_prod': 'https://authn.honeywell.com/idp/startSSO.ping?',
    'SSO_CLOUD_BaseURL_staging': 'https://authn.honeywell.com/idp/startSSO.ping?',
    'SSO_CLOUD_BaseURL_qa': 'https://qauthn.honeywell.com/idp/startSSO.ping?',
    'SSO_CLOUD_BaseURL_psr': 'https://qauthn.honeywell.com/idp/startSSO.ping?',
    'SSO_CLOUD_BaseURL_dev': 'https://qauthn.honeywell.com/idp/startSSO.ping?',
    'SSO_CLOUD_BaseURL_local': 'https://qauthn.honeywell.com/idp/startSSO.ping?',

    // ESP Base URLs
    'ESP_BaseURL_prod': 'https://authn.honeywell.com/idp/startSSO.ping?PartnerSpId=skyconnecttracker.net',
    'ESP_BaseURL_staging': 'https://authn.honeywell.com/idp/startSSO.ping?PartnerSpId=skyconnecttracker.net',
    'ESP_BaseURL_qa': 'https://qauthn.honeywell.com/idp/startSSO.ping?PartnerSpId=skyconnecttracker.net',
    'ESP_BaseURL_psr': 'https://qauthn.honeywell.com/idp/startSSO.ping?PartnerSpId=skyconnecttracker.net',
    'ESP_BaseURL_dev': 'https://qauthn.honeywell.com/idp/startSSO.ping?PartnerSpId=skyconnecttracker.net',
    'ESP_BaseURL_local': 'https://qauthn.honeywell.com/idp/startSSO.ping?PartnerSpId=skyconnecttracker.net',


     // Google Analytics - START
     'google_analytics_id_prod': 'G-E1YX6C7E1N',
     'google_analytics_id_staging': 'G-RWGKCH5KWL',
     'google_analytics_id_qa': 'G-RWGKCH5KWL',
     'google_analytics_id_psr': 'G-RWGKCH5KWL',
     'google_analytics_id_dev': 'G-N3XTY58F5E',
     'google_analytics_id_local': 'G-N3XTY58F5E',

}
