import { useEffect } from "react";
import { ESPUrl } from '../../state-management/urls/BaseURL';
import { getGoogleAnalyticsId} from '../../state-management/urls/BaseURL'
import "./LandingPage.scss";
import DataStorage from "../../utils/data-storage";
import { useNavigate } from "react-router-dom";
import ReactGa from 'react-ga4';

const LandingPage = () => {

    let timer = 1000;
    const navigate = useNavigate();
    useEffect(() => {
        ReactGa.initialize(getGoogleAnalyticsId());
        const adminUser = DataStorage.getItem("userId");
        ReactGa.set({ dimension1: adminUser });
        ReactGa.send({ hitType: "pageview", page: "/landingPage", title: "Heli Landing Page" });

        if (DataStorage.getItem("email") && DataStorage.getItem("refreshJob") && Date.now() - Number(DataStorage.getItem("refreshJob")) < 0) {
            setTimeout(() => {
                const url = ESPUrl();
                window.location.replace(url);
            }, timer);
        }
        else {
            navigate("/");
        }
    }, [])

    return (
        <div className="container">
            <div className="centered">
                <img src="/assets/images/logoHoneywellForge.png" alt="HoneywellForge Logo" />
                <div className="landing-page-text">
                    Taking you to the Asset Tracking portal powered by our partner ESP Logistics.
                </div>
            </div>
        </div>
    )
}

export default LandingPage;
