export const initialState = {
    userInfo: {
        name: '',
        email: '',
    },
    invalidToken: false,
};

export const AuthReducer = (state = initialState, action: any) => {
        return state;
};