import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AuthReducer } from './auth/auth-reducer';

export const rootReducer = combineReducers({
    auth: AuthReducer
});

const store = configureStore({
  reducer:rootReducer
});

export default store;